/* Live */
import cookie from "react-cookies";
//export const apiUrl = "https://walletapi.goepicware.com/api/";
//export const apiUrl = "https://walletapi.goepicpay.com/api/";
export const apiUrl = "https://walletapi.epicpay.ai/api/";

//export const frontURL = "https://wallet.goepicpay.com/";
export const frontURL = "https://wallet.epicpay.ai/";

//export const baseUrl = "http://localhost:3001/";
//export const baseUrl = "https://godmode.goepicpay.com/";
export const baseUrl = "https://godmode.epicpay.ai/";
//export const baseUrl = "https://wallet.goepicware.com/";

export const defaultUniqueID = "11427810-47D6-4977-AC44-CFA53992B77A";
export const adminlimit = "10";
var accesstoken = {
  Authorization: cookie.load("accessToken"),
};

export const masterheaderconfig = {
  headers: accesstoken,
};

var clientaccesstoken = {
  Authorization: cookie.load("clientAccessToken"),
};

export const clientheaderconfig = {
  headers: clientaccesstoken,
};
